import React, { useEffect, useMemo, useRef, useState } from "react";

import UserProjectHeader from "../layouts/UserProjectHeader";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { Modal, Button, Form, Alert, Col, Card } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ChevronDown, ChevronUp, Edit, Filter, Trash2 } from "react-feather";
import ReactApexChart from 'react-apexcharts';
import { Bar } from 'react-chartjs-2';

import config from "../config.json";

const UserDashboard = () => {
  const [projects, setProjects] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newProject, setNewProject] = useState({ projectName: '', description: '' });
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [editProject, setEditProject] = useState(null); // New state for edit
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false); // Separate state for add modal
  const url = config.URL;
  const [filter, setFilter] = useState("");

  const [stats, setStats] = useState({});

  const [modalMode, setModalMode] = useState(null); // 'add' or 'edit'

  const [itemToDelete, setItemToDelete] = useState(null);

  const navigate = useNavigate(); // Create an instance of useNavigate

  const navigateToProjectDetails = (projectId) => {
    navigate(`/user/projects/${projectId}`);
  };



  let one = localStorage.getItem("userData");
  console.log(one);
  let stringToData = JSON.parse(one);
  console.log(stringToData);




  const fetchStats = async () => {
    try {
      const response = await axios.get(`${url}/api/projects/stats/${stringToData.id}`);
      if (response.data.success) {
        setStats(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching statistics", error);
    }
  };

  // Example data for Bar Chart
  const dataBar = {
    labels: ['In Progress', 'On Hold', 'Not Started', 'Overdue'],
    datasets: [
      {
        label: 'Task Status',
        data: [stats.tasksInProgress, stats.tasksOnHold, stats.tasksNotStarted, stats.tasksOverdue],
        backgroundColor: [
          'rgba(0, 0, 255, 0.6)', // Blue
          'rgba(255, 165, 0, 0.6)', // Orange
          'rgba(255, 0, 0, 0.6)', // Red
          'rgba(255, 192, 203, 0.6)', // Pink
        ],
      },
    ],
  };

  // Example options for Bar Chart
  const optionBar = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  // Function to convert string to Title Case
  const toTitleCase = str => str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

  // Function to create chart options (for pie chart)
  const createChartOptions = (labels) => {
    return {
      chart: {
        type: 'pie',
      },
      labels: labels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };
  };



  // Existing code for fetchProjects and useEffect
  const openAddProjectModal = () => {
    setNewProject({ projectName: '', description: '' });
    setShowAddModal(true);
  };

  // Close Add Project Modal
  const closeAddProjectModal = () => {
    setShowAddModal(false);
  };

  // Open Edit Project Modal
  const openEditProjectModal = (project) => {
    setEditProject({ ...project });
    setShowModal(true);
  };

  // Close Edit Project Modal
  const closeEditProjectModal = () => {
    setShowModal(false);
  };


  const closeModal = () => {
    setShowModal(false);
    setModalMode(null); // Reset modal mode
  };

  const handleEdit = (event, project) => {
    event.stopPropagation();

    setEditProject({ ...project });
    setShowModal(true);
  };





  const handleUpdateProject = async () => {
    if (!editProject) return;
    try {
      await axios.put(`${url}/api/projects/update/${editProject.id}`, editProject);
      setAlert({ show: true, message: 'Project Updated Successfully!', type: 'success' });
      setShowModal(false);
      fetchProjects();
    } catch (error) {
      console.error("Error updating project", error);
      setAlert({ show: true, message: 'Error updating project', type: 'danger' });
    }
  };

  const handleEditProjectChange = (e) => {
    setEditProject({ ...editProject, [e.target.name]: e.target.value });
  };

  const openDeleteModal = (event, project) => {
    event.stopPropagation();

    setItemToDelete(project);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setItemToDelete(null);
  };

  const handleDelete = async () => {
    if (!itemToDelete) return;
    try {
      await axios.delete(`${url}/api/projects/delete/${itemToDelete.id}`);
      setAlert({ show: true, message: 'Project Deleted Successfully!', type: 'success' });
      closeDeleteModal();
      fetchProjects();
    } catch (error) {
      console.error("Error deleting project", error);
      setAlert({ show: true, message: 'Error deleting project', type: 'danger' });
    }
  };

  // Function to fetch projects from the API
  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${url}/api/projects/get/${stringToData.id}`);
      console.log(response.data.data)

      setProjects(response.data.data);
    } catch (error) {
      console.error("Error fetching projects", error);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchStats(); // Fetch stats on component mount
  }, []);

  const cards = [
    { count: stats.totalProjects, icon: "ri-article-line", label: "Total Projects" },
    { count: stats.totalTasks, icon: "ri-file-list-line", label: "Total Tasks" },
    { count: stats.tasksCompleted, icon: "ri-check-double-line", label: "Completed" },
    { count: stats.tasksOnHold, icon: "ri-information-line", label: "On Hold" },
    { count: stats.tasksNotStarted, icon: "ri-draft-fill", label: "Not Started" },
    { count: stats.tasksInProgress, icon: "ri-restart-line", label: "In Progress" },
    { count: stats.tasksOverdue, icon: "ri-timer-line", label: "Overdue" },
  ];


  const handleSaveProject = async () => {
    try {
      const response = await axios.post(`${url}/api/projects/add`, newProject);
      setAlert({ show: true, message: 'Project Added Successfully!', type: 'success' });
      setShowAddModal(false); // Close the add project modal
      fetchProjects(); // Refresh the list of projects
    } catch (error) {
      console.error("Error adding project", error);
      setAlert({ show: true, message: 'Error adding project', type: 'danger' });
    }
  };


  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const sortProjectsByTaskCount = (projects) => {
    return projects.slice().sort((a, b) => b.taskCount - a.taskCount);
  };

  const tableHeaders = projects.length > 0 ? Object.keys(projects[0]) : [];

  const renderTableCell = (data) => {
    if (Array.isArray(data)) {
      // Handle array data - example: convert to a comma-separated string
      return data.join(', ');
    } else if (typeof data === 'object' && data !== null) {
      // Handle object data - example: render a specific property or convert to string
      return JSON.stringify(data);
    } else {
      // Handle other data types (numbers, strings, etc.)
      return data;
    }
  };


  const renderCards = () => {
    return cards.map((card, index) => (
      card.count > 0 && renderCard(card.count, card.icon, card.label)
    ));
  };


  return (
    <>
      <UserProjectHeader />
      {/* Existing code for rendering projects */}



      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>

            </ol>
          </div>
          {alert.show && <Alert variant={alert.type}>{alert.message}</Alert>}

        </div>





        {/* Rendering project cards */}

        <div className="row">
          <h2 className="mb-3 left">Task's Stats's</h2>
          {renderCards()}
        </div>


        <div className="row">
          <div className="col-lg-12 mb-4">
            {dataBar && dataBar.length > 0 && (  // Conditional rendering for the entire card
              <Card className="card1" style={{ maxWidth: '600px' }}> {/* Adjust the size as needed */}
                <Card.Body>
                  <h2 className="mb-3">Task's Graphs</h2>
                  <div style={{ position: 'relative', height: '300px' }}> {/* Adjust the chart container */}
                    <Bar
                      data={dataBar}
                      options={optionBar}
                      style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                    />
                  </div>
                </Card.Body>
              </Card>
            )}
          </div>
        </div>







        <Modal show={showAddModal} onHide={closeAddProjectModal} >
          <Modal.Header closeButton>
            <Modal.Title>Add New Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newProject.projectName}
                  onChange={(e) => setNewProject({ ...newProject, projectName: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={newProject.description}
                  onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeAddProjectModal}>Close</Button>
            <Button variant="primary" onClick={handleSaveProject}>Save</Button>
          </Modal.Footer>

        </Modal>

        <Modal show={showModal} onHide={closeEditProjectModal}>

          <Modal.Header closeButton>
            <Modal.Title>Edit Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  name="projectName"
                  value={editProject?.projectName}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={editProject?.description}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeEditProjectModal}>Close</Button>
            <Button variant="primary" onClick={handleUpdateProject}>Update</Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete {itemToDelete?.projectName}?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>


      </div >


    </>
  );
};

export default UserDashboard;

function renderCard(count, icon, label) {
  return (
    <Col xs="12" sm="2">
      <Card className="card-one w-100 card1">
        <Card.Body className="p-3">


          <center>
            <div className="d-block fs-40 lh-1 text-primary mb-1">
              <i className={icon}></i>
            </div>
            <center>
              <h1 className="card-value1 center">{count}</h1>
            </center>
            <label className="d-block mb-1 fw-medium text-dark">{label}</label>
          </center>
        </Card.Body>
      </Card>
    </Col>
  );
}

