import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card } from "react-bootstrap";
import { useTable } from 'react-table';
import config from "../config.json";
import Footer from "../layouts/Footer";
import "./Version.css";
function Version() {
    const url = config.URL;

    const [apiVersions, setApiVersions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/api/versions/getall`);
                if (response.data && response.data.success) {
                    const formattedData = response.data.data.map(item => ({
                        ...item,
                        date: formatDate(item.date)
                    }));
                    setApiVersions(formattedData);

                } else {
                    console.error('API response success flag not set');
                }
            } catch (error) {
                console.error('Error fetching API data:', error);
                // Handle error (show alert or message on UI)
            }
        };

        fetchData();
    }, []);




    const columns = React.useMemo(
        () => [
            {
                Header: 'Version',
                accessor: 'version', // accessor is the "key" in the data
            },
            {
                Header: 'Date of Release',
                accessor: 'date',
            },
            {
                Header: 'Overview',
                accessor: 'overview',
                Cell: ({ value }) => value || 'N/A',
            },
            {
                Header: 'Features',
                accessor: 'features',
                Cell: ({ value }) => (
                    <ul>
                        {value ? value.map((item, index) => <li key={index}>{item}</li>) : <li>N/A</li>}
                    </ul>
                ),
            },
            {
                Header: 'Enhancements',
                accessor: 'enhancements',
                Cell: ({ value }) => (
                    <ul>
                        {value ? value.map((item, index) => <li key={index}>{item}</li>) : <li>N/A</li>}
                    </ul>
                ),
            },
            {
                Header: 'Bug Fixes',
                accessor: 'bugFixes',
                Cell: ({ value }) => (
                    <ul>
                        {value ? value.map((item, index) => <li key={index}>{item}</li>) : <li>N/A</li>}
                    </ul>
                ),
            },
            {
                Header: 'Deprecated',
                accessor: 'deprecated',
                Cell: ({ value }) => value || 'N/A',
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: apiVersions });


    return (

        <>



            <Card className="card-one card2">
                <Card.Body className="px-2 pb-3">
                    <h2>Version Release Archive</h2>
                    <div className="grid-container">
                        <div className="table-responsive">
                            <table {...getTableProps()} className="table-style">
                                <thead className="custom-thead">
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps()}>
                                                    {column.render('Header')}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <Footer />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>


    );
}

export default Version;

function formatDate(dateStr) {
    const dateObj = new Date(dateStr);

    let day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const year = dateObj.getFullYear().toString().substr(-2);

    // Adding suffix to day (st, nd, rd, th)
    let suffix = 'th';
    if (day === 1 || day === 21 || day === 31) {
        suffix = 'st';
    } else if (day === 2 || day === 22) {
        suffix = 'nd';
    } else if (day === 3 || day === 23) {
        suffix = 'rd';
    }

    return `${day}${suffix} ${month} ${year}`;
}
