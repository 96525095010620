/* eslint-disable */
import axios from "axios";
import Prism from "prismjs";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import config from "../config.json";
import Footer from "../layouts/Footer";
import AdminProjectHeader from "../layouts/AdminProjectHeader";

import "./Dashboard.css";

import "chart.js/auto";

// import "./dashboard.css";

function AdminDashboard() {
  const [leadData, setLeadData] = useState([]);
  const [localData, setLocalData] = useState([]);
  const [data, setData] = useState(null);
  const [totalProjects, settotalProjects] = useState([])
  const [budgetData, setBudgetData] = useState([])

  const url = config.URL;

  useEffect(() => {
    // Moved Prism.highlightAll   () outside of the axios request since it's not related to the data fetching.
    Prism.highlightAll();
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}/api/tasks/getleadsdata`);
      // Assuming the response contains an array of lead data,set it in the state.
      setLeadData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData().then((fetchedData) => {
      setData(fetchedData);
    });
  }, []);

  // console.log(leadData);

  const fetchAllData = async () => {
    try {
      const response = await axios.get(`${url}/api/tasks/getall`);
      const val = await axios.get(`${url}/api/projects/count`)
      const budgetResponse = await axios.get(`${url}/api/projects/budgets`);
      const budgetData = budgetResponse.data.data;
      setBudgetData(budgetData); // You'll need to create this state variable

      let valCount = val.data.count;
      settotalProjects(valCount)
      // console.log(response.data);
      // Sort the array in descending order based on the "id" field
      const sortedData = response.data.sort((a, b) => b.id - a.id);

      setLocalData(sortedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchAllData();
    // eslint-disable-next-line
  }, []);



  const Completedcount = localData.filter(
    (project) => project.status === "COMPLETED"
  ).length;
  const onHoldCount = localData.filter(
    (project) => project.status === "ON HOLD"
  ).length;
  const inProgress = localData.filter(
    (project) => project.status === "IN PROGRESS"
  ).length;
  const notStarted = localData.filter(
    (project) => project.status === "NOT STARTED"
  ).length;

  const overDue = localData.filter(
    (project) => project.status === "OVERDUE"
  ).length;

  const userStatusCounts = {};
  localData.forEach((project) => {
    const status = project.status;
    if (!userStatusCounts[status]) {
      userStatusCounts[status] = 1;
    } else {
      userStatusCounts[status]++;
    }
  });

  // setCompletedProjectsCount(Completedcount);
  // console.log(Completedcount);

  // console.log(localData);
  function segregateStatusByLead(projects) {
    const leadStatusCounts = {};

    projects.forEach((project) => {
      const lead = project.lead;
      const status = project.status;

      if (!leadStatusCounts[lead]) {
        leadStatusCounts[lead] = {};
      }

      leadStatusCounts[lead][status] = (leadStatusCounts[lead][status] || 0) + 1;
    });

    return leadStatusCounts;
  }

  // Define a constant width for all the options
  const chartWidth = 100;
  const chartHeight = 100;

  const legendFontStyle = {
    fontFamily: '"Open Sans"', // Use Open Sans as the primary font, fallback to sans-serif if it's not available
    fontSize: "16px", // A legible font size for legends
    fontWeight: "normal", // You can use 'normal' or 'bold' based on your preference
  };

  const segregated = segregateStatusByLead(localData);



  const extractArrays = (obj) => {
    const result = {};

    for (const key in obj) {
      result[key] = {
        keys: Object.keys(obj[key]),
        values: Object.values(obj[key]),
      };
    }

    return result;
  };
  const extractedData = extractArrays(segregated);


  const createChartOptions = (keysArray) => ({
    labels: keysArray,
    legend: {
      fontFamily: legendFontStyle.fontFamily,
      fontSize: legendFontStyle.fontSize,
    },
    responsive: [
      {
        breakpoint: 100,
        options: {
          chart: {
            width: chartWidth,
            height: chartHeight,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });


  const chartOptions = {};
  Object.keys(extractedData).forEach(lead => {
    chartOptions[lead] = createChartOptions(extractedData[lead].keys);
  });


  function segregateStatusByLead(projects) {
    const leadStatusCounts = {};

    projects.forEach((project) => {
      const lead = project.lead;
      const status = project.status;

      if (!leadStatusCounts[lead]) {
        leadStatusCounts[lead] = {};
      }

      leadStatusCounts[lead][status] = (leadStatusCounts[lead][status] || 0) + 1;
    });



    // Convert to array format for pie charts
    const leadsArray = Object.keys(leadStatusCounts).map(lead => {
      return {
        name: lead,
        data: Object.entries(leadStatusCounts[lead]).map(([status, count]) => ({ x: status, y: count }))
      };
    });

    return leadsArray;
  }


  //////////////////////////////////////

  const ctxLabel = leadData.map((item) => item[0]);
  const ctxData1 = leadData.map((item) => item[1]);
  const ctxColor1 = "#506fd9";

  const dataBar = {
    labels: ctxLabel,
    datasets: [
      {
        data: ctxData1,
        backgroundColor: ctxColor1,
        barPercentage: 0.5,
      },
    ],
  };
  const optionBar = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      fontFamily: legendFontStyle.fontFamily,
      fontSize: legendFontStyle.fontSize,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Leads", // Replace with your desired label for the X-axis
          font: {
            family: "Open Sans", // Replace with your custom font family
            size: 16, // Replace with your desired font size
          },
        },
      },
      y: {
        beginAtZero: true,
        max: 50,
        title: {
          display: true,
          text: "Projects", // Replace with your desired label for the Y-axis
          font: {
            family: "Open Sans", // Replace with your custom font family
            size: 16, // Replace with your desired font size
          },
        },
      },
    },
  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const hasData = dataBar.labels.length > 0; // You can adjust this condition based on your data structure

  const cards = [
    { count: totalProjects, icon: "ri-file-list-line", label: "Total Projects" },
    { count: localData.length, icon: "ri-file-list-line", label: "Total Tasks" },
    { count: Completedcount, icon: "ri-check-double-line", label: "Completed" },
    { count: onHoldCount, icon: "ri-information-line", label: "On Hold" },
    { count: notStarted, icon: "ri-draft-fill", label: "Not Started" },
    { count: inProgress, icon: "ri-restart-line", label: "In Progress" },
    { count: overDue, icon: "ri-restart-line", label: "Overdue" },
  ];

  // Sort the cards array in descending order based on count
  cards.sort((a, b) => b.count - a.count);


  return (
    <>
      <AdminProjectHeader />
      <div className="main main-app p-3 p-lg-4">
        <div className="md-flex">
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="#">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {/* Add the current page title here if needed */}
            </li>
          </ol>

          <Col xl="12" className="col-12 pb-3">
            {(cards.length > 0 || hasData || segregated.length > 0) ? (
              <>
                {cards.some(card => card.count > 0) && (
                  <>
                    <br />
                    <h2>Stats</h2>
                    <Row className="g-6 g-md-3 g-lg-2">
                      {cards.map((card, index) => (
                        card.count > 0 && renderCard(card.count, card.icon, card.label)
                      ))}
                    </Row>
                  </>
                )}

                {hasData && (
                  <>
                    <br />
                    <Container>
                      <br />
                      <Row>
                        <Card className="card1">
                          <h2 className="left">Tasks</h2>
                          <Card.Body>
                            <Bar
                              data={dataBar}
                              options={optionBar}
                              height={200}
                              width={200}
                              className="ht-400"
                            />
                          </Card.Body>
                        </Card>
                      </Row>
                    </Container>

                  </>
                )}

                {segregated.length > 0 && (
                  <Container>
                    <br />

                    <Row>
                      {segregated.map(leadData => renderPieChart(leadData))}
                    </Row>
                  </Container>
                )}
                {budgetData && (
                  <Container>
                    <Row>

                      <br />
                      {renderLineChartWithLabels(budgetData)}
                    </Row>
                  </Container>
                )}
              </>
            ) : (
              <h2>No Projects Found</h2>
            )}
          </Col>

        </div>
        <Footer />
      </div >
    </>
  );


  function renderCard(count, icon, label) {
    return (
      <Col xs="12" sm="2">
        <Card className="card-one w-100 card1">
          <Card.Body className="p-3">
            <center>
              <div className="d-block fs-40 lh-1 text-primary mb-1">
                <i className={icon}></i>
              </div>
              <center>
                <h1 className="card-value1 center">{count}</h1>
              </center>
              <label className="d-block mb-1 fw-medium text-dark">{label}</label>
            </center>
          </Card.Body>
        </Card>
      </Col>
    );
  }


  function renderLineChartWithLabels(budgetData) {
    if (!budgetData || !budgetData.projectBudgets) return null;

    // Prepare data for line chart
    const projectNames = budgetData.projectBudgets.map(p => p.projectName);
    const projectBudgets = budgetData.projectBudgets.map(p => parseFloat(p.budgetINR));

    const lineChartOptions = {
      chart: {
        type: 'bar',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories: projectNames,
        type: 'category'
      },
      tooltip: {
        enabled: true
      },
      stroke: {
        curve: 'straight' // or 'smooth' if you prefer
      },
      legend: {
        position: 'top'
      }
    };

    const lineSeries = [{
      name: "Budget INR ₹",
      data: projectBudgets
    }];

    // Prepare data for donut chart
    const donutChartOptions = {
      labels: projectNames,
      plotOptions: {
        pie: {
          donut: {
            size: '45%',
          }
        }
      },
      dataLabels: {
        enabled: true
      },
      legend: {
        position: 'bottom'
      }
    };

    return (
      <Col>
        {budgetData && budgetData.projectBudgets && budgetData.projectBudgets.length > 0 && (

          <Card className="card1">
            <Card.Body>
              <h2>Budgets</h2>

              <div className="d-flex justify-content-around">
                <div>
                  <ReactApexChart
                    options={lineChartOptions}
                    series={lineSeries}
                    type="bar"
                    width={400}
                    height={400}
                  />
                </div>
                <div>
                  <ReactApexChart
                    options={donutChartOptions}
                    series={projectBudgets}
                    type="donut"
                    width={400}
                    height={400}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
      </Col>
    );

  }





  function renderPieChart(leadData) {
    // Define your color mapping
    const colorMapping = {
      "NOT STARTED": "#FF0000", // Red
      "ON HOLD": "#FFA500", // Orange
      "OVERDUE": "#FFC0CB", // Pink
      "IN PROGRESS": "#0000FF", // Blue
      "COMPLETED": "#008000", // Green
    };

    // Create an array of colors for the chart
    const chartColors = leadData.data.map(item => colorMapping[item.x] || "#000"); // Default to black if no match

    return (
      <Col>
        <br />
        <Card className="card1">
          <Card.Body>
            <h2>Charts</h2>
            <center>
              <h2>{toTitleCase(leadData.name)}</h2>
            </center>
            <ReactApexChart
              series={leadData.data.map(item => item.y)}
              options={{
                ...createChartOptions(leadData.data.map(item => item.x)),
                labels: leadData.data.map(item => item.x),
                colors: chartColors // Set the colors for the chart
              }}
              type="pie"
              width={400}
              height={400}
            />
          </Card.Body>
        </Card>
      </Col>
    );
  }


}

export default AdminDashboard;
