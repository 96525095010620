import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Pagination,
  Row,
  Tooltip
} from "react-bootstrap";
import { ChevronDown, ChevronUp, Edit, Filter, Trash2 } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";
import Select from "react-select";
import { useTableContext } from "../Context/TableContext";
import config from "../config.json";
import AdminProjectHeader from "../layouts/AdminProjectHeader";
import Footer from "../layouts/Footer";

import "./Project.css";
const AdminProjectManagement = () => {
  const [projects, setProjects] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newProject, setNewProject] = useState({
    projectName: '',
    description: '',
    plannedStartDate: '',
    actualStartDate: '',
    plannedEndDate: '',
    actualEndDate: '',
    type: '', // Assuming 'Internal' or 'External'
    client: '',
    SoW: '',
    signedDate: '',
    resources: '',
    budgetINR: '',
    majorRisks: '',
    notesComments: '',
  });
  const [editProject, setEditProject] = useState(null); // New state for edit
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const url = config.URL;
  const [modalMode, setModalMode] = useState(null); // 'add' or 'edit'


  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);

  const [filterInput, setFilterInput] = useState("");

  const { globalFilter } = useTableContext();
  const [itemToDelete, setItemToDelete] = useState(null);

  function NumberToWords(number) {
    if (isNaN(number)) {
      console.error('Invalid number');
      return '';
    }

    if (number === 0) return 'Zero';

    const arr = [
      '',
      'One',
      'Two',
      'Three',
      'Four',
      'Five',
      'Six',
      'Seven',
      'Eight',
      'Nine',
      'Ten',
      'Eleven',
      'Twelve',
      'Thirteen',
      'Fourteen',
      'Fifteen',
      'Sixteen',
      'Seventeen',
      'Eighteen',
      'Nineteen',
    ];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    function convert(n) {
      if (n < 20) return arr[n];
      if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 > 0 ? ' ' + arr[n % 10] : '');
      if (n < 1000) return arr[Math.floor(n / 100)] + ' Hundred' + (n % 100 > 0 ? ' ' + convert(n % 100) : '');
      if (n < 100000) return convert(Math.floor(n / 1000)) + ' Thousand' + (n % 1000 > 0 ? ' ' + convert(n % 1000) : '');
      if (n < 10000000) return convert(Math.floor(n / 100000)) + ' Lakh' + (n % 100000 > 0 ? ' ' + convert(n % 100000) : '');
      if (n < 1000000000) return convert(Math.floor(n / 10000000)) + ' Crore' + (n % 10000000 > 0 ? ' ' + convert(n % 10000000) : '');
      return 'Number too large';
    }

    return convert(number);
  }







  const navigate = useNavigate(); // Create an instance of useNavigate

  // Function to fetch projects from the API
  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${url}/api/projects/getall`);
      // Assuming response.data.data is an array of projects
      const sortedProjects = response.data.data.sort((a, b) => {
        // Replace 'createdAt' with 'updatedAt' or any other relevant field if needed
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setProjects(sortedProjects);
    } catch (error) {
      console.error("Error fetching projects", error);
    }
  };


  useEffect(() => {
    fetchProjects();
  }, []);

  const navigateToProjectDetails = (projectId) => {
    navigate(`/admin/projects/${projectId}`);
  };

  // Existing code for fetchProjects and useEffect
  const openAddProjectModal = () => {
    setNewProject({ projectName: '', description: '' });
    setShowAddModal(true);
  };

  // Close Add Project Modal
  const closeAddProjectModal = () => {
    setShowAddModal(false);
  };

  // Open Edit Project Modal
  const openEditProjectModal = (project) => {
    setEditProject({ ...project });
    setShowModal(true);
  };

  // Close Edit Project Modal
  const closeEditProjectModal = () => {
    setShowModal(false);
  };


  const closeModal = () => {
    setShowModal(false);
    setModalMode(null); // Reset modal mode
  };

  const handleEdit = (event, project) => {
    event.stopPropagation();

    // Set the project data in the editProject state, including date fields
    setEditProject({
      ...project,
      plannedStartDate: project.plannedStartDate ? project.plannedStartDate.split('T')[0] : '',
      actualStartDate: project.actualStartDate ? project.actualStartDate.split('T')[0] : '',
      plannedEndDate: project.plannedEndDate ? project.plannedEndDate.split('T')[0] : '',
      actualEndDate: project.actualEndDate ? project.actualEndDate.split('T')[0] : '',
      SoW: project.SoW ? project.SoW.split('T')[0] : '', // Added SoW date
      signedDate: project.signedDate ? project.signedDate.split('T')[0] : '' // Added signedDate
    });
    setShowModal(true);
  };



  const handleUpdateProject = async () => {
    if (!editProject) return;
    try {
      await axios.put(`${url}/api/projects/update/${editProject.id}`, editProject);
      showAlert("Project Updated Successfully!", "success");
      setShowModal(false);
      fetchProjects();
    } catch (error) {
      console.error("Error updating project", error);
      showAlert(`${error.response?.data.message || "An error occurred"}`, "danger");

    }
  };

  const handleEditProjectChange = (e) => {
    setEditProject({ ...editProject, [e.target.name]: e.target.value });
  };


  function formatNumberIndianStyle(number) {
    // Convert the number to a string for easier manipulation
    number = number.toString();

    // First, separate the last three digits (thousands)
    let lastThreeDigits = number.length > 3 ? number.substr(number.length - 3) : number;
    let otherDigits = number.length > 3 ? number.substr(0, number.length - 3) : '';

    // Then, insert a comma after every two digits from the right for the remaining digits
    otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',');

    // Combine the two parts with a comma
    return otherDigits.length ? otherDigits + ',' + lastThreeDigits : lastThreeDigits;
  }




  const openDeleteModal = (event, project) => {
    event.stopPropagation();

    setItemToDelete(project);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setItemToDelete(null);
  };

  // eslint-disable-next-line
  const showAlert = (message, type) => {
    setAlertMessage(message);
    setAlertType(type); // 'success' or 'danger'

    // Hide the alert after 5 seconds
    const id = setTimeout(() => {
      setAlertMessage("");
      setAlertType("");
    }, 4000);

    setTimeoutId(id); // Store the timeout ID for possible cleanup
  };
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const handleDelete = async () => {
    if (!itemToDelete) return;
    try {
      await axios.delete(`${url}/api/projects/delete/${itemToDelete.id}`);
      showAlert("Project Deleted Successfully!", "success");
      // Refresh the page
      window.location.reload();
    } catch (error) {
      console.error("Error deleting project", error);
      showAlert(`${error.response?.data.message || "An error occurred"}`, "danger");
    }
  };




  const handleSaveProject = async () => {
    try {
      const response = await axios.post(`${url}/api/projects/add`, newProject);
      showAlert("Project Added Successfully!", "success");

      setShowAddModal(false); // Close the add project modal
      fetchProjects(); // Refresh the list of projects
    } catch (error) {
      console.error("Error adding project", error);
      showAlert(`${error.response?.data.message || "An error occurred"}`, "danger");
    }
  };


  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };





  function toTitleCase(str) {
    if (typeof str !== "string" || str === null || str === undefined) {
      return ""; // or return str; depending on your desired behavior
    }

    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
    );
  }

  const CustomSelect = ({ options, value, onChange }) => {
    return (
      <Select
        options={options}
        isSearchable={true}
        value={options.find((option) => option.value === value)}
        onChange={(selectedOption) => onChange(selectedOption.value)} // Pass the selected value directly
      />
    );
  };


  // const CustomSelect = ({ options, value, onChange }) => {
  //   return (
  //     <Select
  //       options={options}
  //       isSearchable={true}
  //       value={options.find((option) => option.value === value)}
  //       onChange={onChange} // Keep as is, since the onChange handler is already adapted above
  //     />
  //   );
  // };



  // /////////////////
  const dropdownRef = useRef(null);

  const [openFilter, setOpenFilter] = useState(null);
  const multiSelectFilterFn = (rows, id, filterValues) => {
    if (!filterValues.length) {
      return rows;
    }
    return rows.filter((row) => filterValues.includes(row.values[id]));
  };

  function getFirstFiveWords(str) {
    const words = str.split(' '); // Split the string into words
    return words.slice(0, 5).join(' '); // Join the first 5 words
  }


  const columns = useMemo(() => [
    {
      Header: 'Project Name',
      accessor: 'projectName',
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      Cell: ({ value }) => {
        // Check if value is not null or undefined before accessing its length
        if (value) {
          const firstFiveWords = getFirstFiveWords(value);
          const displayText = firstFiveWords.length < value.length ? firstFiveWords + "..." : firstFiveWords;

          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {value}
                </Tooltip>
              }
            >
              <div style={{ textAlign: "left" }}>{displayText}</div>
            </OverlayTrigger>
          );
        }
      },
      // Additional properties like Filter can be added if needed
    },
    {
      Header: 'Description',
      accessor: 'description',
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      Cell: ({ value }) => {
        // Check if value is not null or undefined before accessing its length
        if (value) {
          const firstFiveWords = getFirstFiveWords(value);
          const displayText = firstFiveWords.length < value.length ? firstFiveWords + "..." : firstFiveWords;

          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {value}
                </Tooltip>
              }
            >
              <div style={{ textAlign: "left" }}>{displayText}</div>
            </OverlayTrigger>
          );
        } else {
          // Handle the case where value is null or undefined
          return <div style={{ textAlign: "left" }}>No Description</div>;
        }
      },


    },
    {
      Header: 'P.S.D',
      accessor: 'plannedStartDate',
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      dataType: 'DATE', // Add this line to your column definitions

      Cell: ({ value }) => (
        <div style={{ textAlign: "center" }}>{formatDate(value)}</div>
      ),
    },
    {
      Header: 'A.S.D',
      accessor: 'actualStartDate',
      Filter: DropdownFilter,
      dataType: 'DATE', // Add this line to your column definitions

      filter: multiSelectFilterFn,
      Cell: ({ value }) => (
        <div style={{ textAlign: "center" }}>{formatDate(value)}</div>
      ),
    },
    {
      Header: 'P.E.D',
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      dataType: 'DATE', // Add this line to your column definitions

      accessor: 'plannedEndDate',
      Cell: ({ value }) => (
        <div style={{ textAlign: "center" }}>{formatDate(value)}</div>
      ),
    },
    {
      Header: 'A.E.D',
      accessor: 'actualEndDate',
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      dataType: 'DATE', // Add this line to your column definitions
      Cell: ({ value }) => (
        <div style={{ textAlign: "center" }}>{formatDate(value)}</div>
      ),
    },
    {
      Header: 'Type',
      accessor: 'type',
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,

      // Additional properties like Filter can be added if needed
    },

    {
      Header: 'Client',
      accessor: 'client',
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      Cell: ({ value }) => {
        // Check if value is not null or undefined before accessing its length
        if (value) {
          const firstFiveWords = getFirstFiveWords(value);
          const displayText = firstFiveWords.length < value.length ? firstFiveWords + "..." : firstFiveWords;

          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {value}
                </Tooltip>
              }
            >
              <div style={{ textAlign: "left" }}>{displayText}</div>
            </OverlayTrigger>
          );
        } else {
          // Handle the case where value is null or undefined
          return <div style={{ textAlign: "left" }}>-</div>;
        }
      },
      // Additional properties like Filter can be added if needed
    },
    {
      Header: 'SoW',
      accessor: 'SoW',
      dataType: 'DATE', // Add this line to your column definitions
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      Cell: ({ value }) => (
        <div style={{ textAlign: "center" }}>{formatDate(value)}</div>
      ),
      // Additional properties like Filter can be added if needed
    },
    {
      Header: 'Signed Date',
      accessor: 'signedDate',
      dataType: 'DATE', // Add this line to your column definitions
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      Cell: ({ value }) => (
        <div style={{ textAlign: "center" }}>{formatDate(value)}</div>
      ),
      // Additional properties like Filter can be added if needed
    },
    {
      Header: 'Resources',
      accessor: 'resources',
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      Cell: ({ value }) => {
        // Check if value is not null or undefined before accessing its length
        if (value) {
          const firstFiveWords = getFirstFiveWords(value);
          const displayText = firstFiveWords.length < value.length ? firstFiveWords + "..." : firstFiveWords;

          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {value}
                </Tooltip>
              }
            >
              <div style={{ textAlign: "left" }}>{displayText}</div>
            </OverlayTrigger>
          );
        } else {
          // Handle the case where value is null or undefined
          return <div style={{ textAlign: "left" }}>-</div>;
        }
      },
      // Additional properties like Filter can be added if needed
    },
    {
      Header: 'Budget ₹ (INR)',
      accessor: 'budgetINR',
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      Cell: ({ value }) => {
        // Convert value to number if it's a string
        const numericValue = typeof value === 'string' ? parseInt(value, 10) : value;

        // Check if value is null, undefined, or not a number
        if (numericValue === null || numericValue === undefined || isNaN(numericValue)) {
          return null;
        }

        // Convert number to words in Indian format
        const words = NumberToWords(numericValue);
        // Convert words to Title Case
        const titleCaseWords = toTitleCase(words);

        // Format number with Indian style commas
        const formattedValue = formatNumberIndianStyle(numericValue);

        return (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-${numericValue}`}>
                {titleCaseWords}
              </Tooltip>
            }
          >
            <div style={{ textAlign: "right" }}>{`${formattedValue}`}</div>
          </OverlayTrigger>
        );
      },
    },



    {
      Header: 'Major Risks',
      accessor: 'majorRisks',
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      Cell: ({ value }) => {
        // Check if value is not null or undefined before accessing its length
        if (value) {
          const firstFiveWords = getFirstFiveWords(value);
          const displayText = firstFiveWords.length < value.length ? firstFiveWords + "..." : firstFiveWords;

          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {value}
                </Tooltip>
              }
            >
              <div style={{ textAlign: "left" }}>{displayText}</div>
            </OverlayTrigger>
          );
        } else {
          // Handle the case where value is null or undefined
          return <div style={{ textAlign: "left" }}>-</div>;
        }
      },
      // Additional properties like Filter can be added if needed
    },
    {
      Header: 'Notes/Comments',
      accessor: 'notesComments',
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      Cell: ({ value }) => {
        // Check if value is not null or undefined before accessing its length
        if (value) {
          const firstFiveWords = getFirstFiveWords(value);
          const displayText = firstFiveWords.length < value.length ? firstFiveWords + "..." : firstFiveWords;

          return (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {value}
                </Tooltip>
              }
            >
              <div style={{ textAlign: "left" }}>{displayText}</div>
            </OverlayTrigger>
          );
        } else {
          // Handle the case where value is null or undefined
          return <div style={{ textAlign: "left" }}>-</div>;
        }
      },

      // Additional properties like Filter can be added if needed
    },
    {
      Header: 'Total Milestones',
      accessor: 'taskCount',
      Filter: DropdownFilter,
      filter: multiSelectFilterFn,
      Cell: ({ value }) => (
        <div style={{ textAlign: "center" }}>
          {value || '0'}
        </div>
      )
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>
          <span
            className="edit-icon"
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click event
              handleEdit(e, row.original);
            }}
          >
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  <strong>Edit</strong>
                </Tooltip>
              }
            >
              <Edit size={20} color="blue" style={{ cursor: 'pointer' }} />
            </OverlayTrigger>
          </span>

          <span
            className="delete-icon"
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click event
              openDeleteModal(e, row.original);
            }}
          >
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  <strong>Delete</strong>
                </Tooltip>
              }
            >
              <Trash2 size={20} color="red" style={{ cursor: 'pointer', marginLeft: '10px' }} />
            </OverlayTrigger>
          </span>
        </div>
      ),
    }


  ], []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenFilter(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    // eslint-disable-next-line
    state: { pageIndex, pageSize },
    nextPage,
    previousPage,
    setGlobalFilter,
    gotoPage, // Add gotoPage as a prop
    canNextPage,
    pageCount,
    canPreviousPage,
  } = useTable(
    {
      columns,
      data: projects,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        globalFilter: "",
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useResizeColumns,
    useGlobalFilter,
    useSortBy,
    useResizeColumns,
    usePagination
  );

  React.useEffect(() => {
    setGlobalFilter(globalFilter); // This is how you set the global filter
  }, [globalFilter, setGlobalFilter]);

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value); // Set the filter globally
    setFilterInput(value); // Update the state with the new search input
  };
  const itemsPerPage = 4;
  const [currentPageGroup, setCurrentPageGroup] = useState(0);

  // Calculate the range of pages to display based on the current group
  const startIndex = currentPageGroup * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Create an array of page numbers for the current group
  const circleOptions = Array.from(
    { length: pageCount },
    (_, index) => index + 1
  ).slice(startIndex, endIndex);




  return (
    <>
      <AdminProjectHeader />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Project Management</Link>
              </li>

            </ol>
          </div>
          <div style={{ textAlign: "center", padding: "10px" }}>
            {alertMessage && <Alert variant={alertType}>{alertMessage}</Alert>}
          </div>
          <Button variant="primary" onClick={openAddProjectModal}>Add Project</Button>
        </div>

        {/* //////////////Add ////// */}

        <Modal show={showAddModal} onHide={closeAddProjectModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newProject.projectName}
                  onChange={(e) => setNewProject({ ...newProject, projectName: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={newProject.description}
                  onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
                />
              </Form.Group>

              {/* Additional date fields */}
              <Form.Group>
                <Form.Label>Planned Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={newProject.plannedStartDate}
                  onChange={(e) => setNewProject({ ...newProject, plannedStartDate: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Actual Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={newProject.actualStartDate}
                  onChange={(e) => setNewProject({ ...newProject, actualStartDate: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Planned End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={newProject.plannedEndDate}
                  onChange={(e) => setNewProject({ ...newProject, plannedEndDate: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Actual End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={newProject.actualEndDate}
                  onChange={(e) => setNewProject({ ...newProject, actualEndDate: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <CustomSelect
                  options={[
                    { value: '', label: 'Select Type' },
                    { value: 'internal', label: 'Internal' },
                    { value: 'external', label: 'External' },
                  ]}
                  value={newProject.type}
                  onChange={(selectedType) => setNewProject({ ...newProject, type: selectedType })}
                />
              </Form.Group>



              {/* Client Field */}
              <Form.Group>
                <Form.Label>Client</Form.Label>
                <Form.Control
                  type="text"
                  value={newProject.client}
                  onChange={(e) => setNewProject({ ...newProject, client: e.target.value })}
                />
              </Form.Group>

              {/* SoW Field */}
              <Form.Group>
                <Form.Label>Statement of Work (SoW) Date</Form.Label>
                <Form.Control
                  type="date"
                  value={newProject.SoW}
                  onChange={(e) => setNewProject({ ...newProject, SoW: e.target.value })}
                />
              </Form.Group>

              {/* Signed Date Field */}
              <Form.Group>
                <Form.Label>Signed Date</Form.Label>
                <Form.Control
                  type="date"
                  value={newProject.signedDate}
                  onChange={(e) => setNewProject({ ...newProject, signedDate: e.target.value })}
                />
              </Form.Group>

              {/* Resources Field */}
              <Form.Group>
                <Form.Label>Resources</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={newProject.resources}
                  onChange={(e) => setNewProject({ ...newProject, resources: e.target.value })}
                />
              </Form.Group>

              {/* Budget Field */}
              <Form.Group>
                <Form.Label>Budget (INR)</Form.Label>
                <Form.Control
                  type="text"
                  value={newProject.budgetINR}
                  onChange={(e) => setNewProject({ ...newProject, budgetINR: e.target.value })}
                />
              </Form.Group>
              <div style={{ textAlign: 'center', padding: '10px' }}>
                {alertMessage && <Alert variant={alertType}>{alertMessage}</Alert>}
              </div>



              {/* Major Risks Field */}
              <Form.Group>
                <Form.Label>Major Risks</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={newProject.majorRisks}
                  onChange={(e) => setNewProject({ ...newProject, majorRisks: e.target.value })}
                />
              </Form.Group>

              {/* Notes/Comments Field */}
              <Form.Group>
                <Form.Label>Notes/Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={newProject.notesComments}
                  onChange={(e) => setNewProject({ ...newProject, notesComments: e.target.value })}
                />
              </Form.Group>


            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeAddProjectModal}>Close</Button>
            <Button variant="primary" onClick={handleSaveProject}>Save</Button>
          </Modal.Footer>
        </Modal>



        {/* ////////////// Edit Model //////////////////////////// */}
        <Modal show={showModal} onHide={closeEditProjectModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  name="projectName"
                  value={editProject?.projectName}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={editProject?.description}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>

              {/* Additional date fields */}
              <Form.Group>
                <Form.Label>Planned Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="plannedStartDate"
                  value={editProject?.plannedStartDate}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Actual Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="actualStartDate"
                  value={editProject?.actualStartDate}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Planned End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="plannedEndDate"
                  value={editProject?.plannedEndDate}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Actual End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="actualEndDate"
                  value={editProject?.actualEndDate}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>

              {/* <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={editProject?.type || ''}
                  onChange={handleEditProjectChange}
                >
                  <option value="">Select Type</option>
                  <option value="Internal">Internal</option>
                  <option value="External">External</option>
                </Form.Control>
              </Form.Group> */}
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <CustomSelect
                  options={[
                    { value: '', label: 'Select Type' },
                    { value: 'internal', label: 'Internal' },
                    { value: 'external', label: 'External' },
                  ]}
                  value={editProject?.type || ''}
                  onChange={(selectedType) => setEditProject({ ...editProject, type: selectedType.value })}
                />
              </Form.Group>






              {/* Client Field */}
              <Form.Group>
                <Form.Label>Client</Form.Label>
                <Form.Control
                  type="text"
                  name="client"
                  value={editProject?.client}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>

              {/* SoW Field */}
              <Form.Group>
                <Form.Label>Statement of Work (SoW) Date</Form.Label>
                <Form.Control
                  type="date"
                  name="SoW"
                  value={editProject?.SoW}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>

              {/* Signed Date Field */}
              <Form.Group>
                <Form.Label>Signed Date</Form.Label>
                <Form.Control
                  type="date"
                  name="signedDate"
                  value={editProject?.signedDate}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>

              {/* Resources Field */}
              <Form.Group>
                <Form.Label>Resources</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="resources"
                  value={editProject?.resources}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>

              {/* Budget Field */}
              <Form.Group>
                <Form.Label>Budget (INR)</Form.Label>
                <Form.Control
                  type="text"
                  name="budgetINR"
                  value={editProject?.budgetINR}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>

              <div style={{ textAlign: 'center', padding: '10px' }}>
                {alertMessage && <Alert variant={alertType}>{alertMessage}</Alert>}
              </div>
              {/* Major Risks Field */}
              <Form.Group>
                <Form.Label>Major Risks</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="majorRisks"
                  value={editProject?.majorRisks}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>

              {/* Notes/Comments Field */}
              <Form.Group>
                <Form.Label>Notes/Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="notesComments"
                  value={editProject?.notesComments}
                  onChange={handleEditProjectChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeEditProjectModal}>Close</Button>
            <Button variant="primary" onClick={handleUpdateProject}>Update</Button>
          </Modal.Footer>
        </Modal>


        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete
            <span style={{ color: 'red' }}> {itemToDelete?.projectName} </span>?
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={closeDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>



        <Card className="card-one card2">
          <Card.Body className="px-2 pb-3">
            <div className="grid-container">
              {/* Table */}
              <div className="table-responsive">
                <table {...getTableProps()} className="table-style">

                  <thead className="custom-thead">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, columnIndex) => (
                          <th
                            {...column.getHeaderProps()}
                            className="header-style text-center"
                            key={column.id}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                <span>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <ChevronDown size={16} />
                                    ) : (
                                      <ChevronUp size={16} />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              {/* {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(columnIndex) && column.canFilter && ( */}
                              {[].includes(columnIndex) && column.canFilter && (
                                <div
                                  className="filter-align"
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent sorting when clicking on filter icon
                                    openFilter === column.id
                                      ? setOpenFilter(null)
                                      : setOpenFilter(column.id);
                                  }}
                                >
                                  <Filter size={14} />
                                </div>
                              )}
                              {openFilter === column.id && (
                                <div className="filter-dropdown" ref={dropdownRef}>
                                  {column.render("Filter")}
                                </div>
                              )}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  {/* Tbody */}
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} style={{ cursor: "pointer" }}>
                          {row.cells.map((cell) => {
                            const cellProps = cell.getCellProps({
                              className: "cell-style",
                              onClick: cell.column.id !== 'id' ? () => navigateToProjectDetails(row.original.id) : null
                            });

                            return <td {...cellProps}>{cell.render("Cell")}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>

                </table>
              </div>
              <Pagination className="pagination-space pagination-circled mb-0">
                <Pagination.Item
                  className="pagination-button"
                  disabled={!canPreviousPage}
                  onClick={() => {
                    if (currentPageGroup > 0) {
                      previousPage();
                      setCurrentPageGroup(currentPageGroup - 1); // Decrement the currentPageGroup
                    }
                  }}
                >
                  <i className="ri-arrow-left-s-line"></i>
                </Pagination.Item>
                <div className="pagination-circle-container">
                  {circleOptions.map((page) => (
                    <Pagination.Item
                      key={page} // Add the key prop here
                      active={pageIndex + 1 === page}
                      onClick={() => gotoPage(page - 1)}
                      className={`pagination-circle ${pageIndex + 1 === page ? "active" : ""
                        }`}
                    >
                      {page}
                    </Pagination.Item>
                  ))}
                </div>
                <Pagination.Item
                  className="pagination-button"
                  disabled={!canNextPage}
                  onClick={() => {
                    nextPage();
                    setCurrentPageGroup(currentPageGroup + 1); // Increment the currentPageGroup
                  }}
                >
                  <i className="ri-arrow-right-s-line"></i>
                </Pagination.Item>
              </Pagination>


            </div>
            <Footer />
          </Card.Body>
        </Card>

      </div >


    </>
  );
};

export default AdminProjectManagement;





function toTitleCase(str) {
  if (!str) {
    // Return an empty string or any other default value if str is null or undefined
    return '';
  }

  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}



const DropdownFilter = ({ column }) => {
  const { filterValue = [], setFilter, preFilteredRows, id } = column;



  // Function to format the value based on its data type
  const formatValue = (value, dataType) => {
    switch (dataType) {
      case 'DATE':
        return formatDate(value);
      case 'ENUM':
      case 'STRING':
        return value ? value.toString() : 'N/A';
      default:
        return value ? value.toString() : 'N/A';
    }
  };

  // State to manage "Select All" functionality
  const [selectAll, setSelectAll] = useState(false);

  // Get unique values from preFilteredRows
  const options = useMemo(() => {
    const optionsSet = new Set();
    preFilteredRows.forEach((row) => {
      const formattedValue = formatValue(row.values[id], column.dataType);
      optionsSet.add(formattedValue);
    });
    return Array.from(optionsSet);
  }, [id, preFilteredRows, column.dataType]);

  const toggleOption = (option) => {
    const index = filterValue.indexOf(option);
    const newFilterValue = [...filterValue];
    if (index === -1) {
      newFilterValue.push(option);
    } else {
      newFilterValue.splice(index, 1);
    }
    setFilter(newFilterValue.length ? newFilterValue : undefined);
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setFilter(selectAll ? [] : options);
  };

  useEffect(() => {
    // Automatically update selectAll based on filterValue
    setSelectAll(filterValue.length === options.length);
  }, [filterValue, options]);

  return (
    <div className="filter-dropdown">
      <div className="filter-header">
        <button className="check-all-button" onClick={toggleSelectAll}>
          {selectAll ? "Uncheck All" : "Check All"}
        </button>
      </div>
      <div className="filter-body">
        {options.map((option, index) => (
          <label key={index} className="checkbox-label">
            <input
              type="checkbox"
              value={option}
              checked={filterValue.includes(option)}
              onChange={() => toggleOption(option)}
            />
            {option}
          </label>
        ))}
      </div>
      <div className="filter-footer">
        <button className="clear-filter" onClick={() => setFilter(undefined)}>
          Clear
        </button>
      </div>
    </div>
  );
};


function formatDate(dateString) {
  if (!dateString) {
    return '-';
  }

  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear().toString().substr(-2);

  const ordinalSuffix = (n) => {
    if (n > 3 && n < 21) return 'th'; // for numbers like 11th, 12th, 13th, etc.
    switch (n % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  return `${day}${ordinalSuffix(day)} ${month} ${year}`;
}