import React from "react";
import { Link } from "react-router-dom";

import config from "../config.json";
export default function Footer() {
  // Get the current year dynamically
  let version = config.VERSION;
  const currentYear = new Date().getFullYear();

  return (
    <div className="main-footer">
      <span>
        &copy; {currentYear} T-WORKS. All Rights Reserved. Version -
        <Link to="https://work.tworks.in/api/versions" target="_blank">
          {version}
        </Link>
      </span>
      <span>
        Made with 💖:{" "}
        <Link to="https://tworks.in" target="_blank">
          T-WORKS
        </Link>
      </span>
    </div>
  );
}
