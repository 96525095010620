/* eslint-disable */
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Alert,
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardText,
    Col,
    Container,
    Form,
    Modal,
    OverlayTrigger,
    Pagination,
    Row,
    Tooltip
} from "react-bootstrap";
import { ChevronDown, ChevronUp, Edit, Filter, Trash2 } from "react-feather";
import { BsFillChatQuoteFill, BsFillLightbulbFill } from 'react-icons/bs'; // Example icons
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import {
    useFilters,
    useGlobalFilter,
    usePagination,
    useResizeColumns,
    useSortBy,
    useTable,
} from "react-table";
import { getLeads, getRest } from "../apps/data/Leadowner";
import "../assets/css/react-datepicker.min.css";
import Avatar from "../components/Avatar";
import config from "../config.json";
import AdminProjectHeader from "../layouts/AdminProjectHeader";
import Footer from "../layouts/Footer";
import "./Task.css";
import dummyImage from "/home/suhail/Task-Management/client/src/assets/users/user.png";

import { useTableContext } from "../Context/TableContext";

// import "../";
///////////////////////////////////////////////////////////

const AdminTaskManagement = () => {
    const url = config.URL;
    const navigate = useNavigate();
    const { projectId } = useParams();

    const { globalFilter } = useTableContext();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

    const [localData, setLocalData] = useState([]);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [timeoutId, setTimeoutId] = useState(null);

    // Task Data
    const [taskData, setTaskData] = useState({
        taskName: "",
        description: "",
        lead: "",
        owner: "",
        priority: "MEDIUM",
        status: "NOT STARTED",
        plannedStartDate: "",
        actualStartDate: "",
        plannedEndDate: "",
        actualEndDate: "",
        notes: '',

    });

    const [leadImage, setLeadImage] = useState("");
    const [ownerImage, setOwnerImage] = useState("");

    const convertUserToOption = (user) => ({
        value: user.id,
        label: user.displayName // or user.displayName, depending on what you want to show
    });


    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        // Process the data after 'localData' has been updated
        if (localData && localData.length > 0) {
            const firstProject = localData[0];
            console.log(firstProject, "first");

            if (firstProject.leadUser && firstProject.leadUser.profileImage) {
                setLeadImage(firstProject.leadUser.profileImage || dummyImage);
            }

            if (firstProject.ownerUser && firstProject.ownerUser.profileImage) {
                setOwnerImage(firstProject.ownerUser.profileImage || dummyImage);
            }
        }
    }, [localData]); // This useEffect depends on 'localData'

    // eslint-disable-next-line
    const showAlert = (message, type) => {
        setAlertMessage(message);
        setAlertType(type); // 'success' or 'danger'

        // Hide the alert after 5 seconds
        const id = setTimeout(() => {
            setAlertMessage("");
            setAlertType("");
        }, 5000);

        setTimeoutId(id); // Store the timeout ID for possible cleanup
    };
    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    // eslint-disable-next-line
    const [description, setDescription] = useState("");
    // eslint-disable-next-line

    const [leadSelectOptions, setLeadSelectOptions] = useState([]);
    const [ownerSelectOptions, setOwnerSelectOptions] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const leads = await getLeads();
            console.log(leads);

            const owners = await getRest();
            console.log(owners);

            setLeadSelectOptions(leads);
            setOwnerSelectOptions(owners);
        }

        fetchData();
    }, []);

    useEffect(() => {
        // existing code...
    }, [leadSelectOptions, ownerSelectOptions]);

    // eslint-disable-next-line
    const [selectedLead, setSelectedLead] = useState("");
    // eslint-disable-next-line
    const [filterInput, setFilterInput] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [showEmptyFieldAlert, setShowEmptyFieldAlert] = useState(false);
    const [images, setImages] = useState({});


    const openDeleteModal = (item) => {
        setItemToDelete(item);
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setItemToDelete(null);
        setShowDeleteModal(false);
    };

    // eslint-disable-next-line
    const [selectedOwner, setSelectedOwner] = useState("");
    const [editingItem, setEditingItem] = useState(null);
    const dropdownRef = useRef(null);

    const [openFilter, setOpenFilter] = useState(null);
    const multiSelectFilterFn = (rows, id, filterValues) => {
        if (!filterValues.length) {
            return rows;
        }
        return rows.filter((row) => filterValues.includes(row.values[id]));
    };

    // Handle modal open and close for Add
    const openAddModal = () => {
        setTaskData({
            taskName: "",
            lead: "",
            owner: "",
            priority: "",
            status: "",
            plannedStartDate: "",
            actualStartDate: "",
            plannedEndDate: "",
            actualEndDate: "",
            notes: '',

        });
        setShowAddModal(true);
    };

    const closeAddModal = () => {
        setShowAddModal(false);
        // Clear form fields for Add modal (if necessary)
        setDescription("");
        setSelectedLead("");
        setSelectedOwner("");
    };

    // Handle modal open and close for Edit
    // eslint-disable-next-line
    const openEditModal = () => {
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
        // Clear form fields for Edit modal (if necessary)
        setDescription("");
        setSelectedLead("");
        setSelectedOwner("");
    };

    /////////////////////////// Add Data ///////////////////////////////




    const handleSubmitAdd = async (e) => {
        e.preventDefault();

        console.log(taskData);


        // Check if any required field is empty
        if (
            !taskData.taskName ||
            !taskData.lead ||
            !taskData.owner ||
            !taskData.priority ||
            !taskData.status
        ) {
            showAlert("Please complete the following * fields.", "danger");
            return;
        }

        try {






            // Include the projectId in the API URL
            await axios.post(`${url}/api/tasks/add/${projectId}`, taskData);

            // Clear the taskData object after successful submission
            setTaskData({
                taskName: "",
                lead: "",
                owner: "",
                priority: "",
                status: "",
                plannedStartDate: "",
                actualStartDate: "",
                plannedEndDate: "",
                actualEndDate: "",
                notes: '',

            });

            closeAddModal();

            showAlert("Task Added Successfully!", "success");
            fetchData();
        } catch (error) {
            console.error("Error: ", error);
            showAlert(`${error.response?.data.message || "An error occurred"}`, "danger");
        }
    };


    // ////////////////////////// Edit Data /////////////////////////////////////////////////
    const handleSubmitEdit = async (e) => {
        e.preventDefault();

        // Check if the required fields are present
        if (!taskData.taskName || !taskData.lead?.value || !taskData.owner?.value || !taskData.priority || !taskData.status) {
            showAlert("Please complete the following * fields.", "danger");
            return;
        }

        // Prepare the data for the API call
        const updatedTaskData = {
            ...taskData,
            lead: taskData.lead.value, // Send only the ID of the lead
            owner: taskData.owner.value, // Send only the ID of the owner
        };

        try {
            // Perform the API call
            await axios.put(`${url}/api/tasks/task/${editingItem.id}`, updatedTaskData);
            closeEditModal();
            setEditingItem(null);
            showAlert("Task Updated Successfully!", "success");
            fetchData();
        } catch (error) {
            console.error("Error: ", error);
            showAlert(`${error.response?.data.message || "An error occurred"}`, "danger");
        }
    };



    // ///////////////////////Delete Data //////////////////////////



    const handleDelete = async () => {
        console.log(itemToDelete.id);

        // Check if itemToDelete and itemToDelete.id are defined
        if (!itemToDelete || !itemToDelete.id) {
            console.error("Error: itemToDelete is not defined or does not have an id.");
            showAlert("An error occurred", "danger");
            return;
        }

        try {
            const response = await axios.delete(`${url}/api/tasks/delete/${itemToDelete.id}`);

            if (response.data.success) {
                setLocalData(localData.filter((item) => item.id !== itemToDelete.id));
                setShowDeleteModal(false);
                showAlert("Task Deleted Successfully!", "success");
            } else {
                showAlert(response.data.message, "danger");
            }
        } catch (error) {
            console.error("Error: ", error);
            showAlert(`${error.response?.data.message || "An error occurred"}`, "danger");
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);


    const fetchData = async () => {
        // Check if projectId is valid (not null, undefined, or empty)
        if (!projectId) {
            console.log("No project ID provided. Aborting fetch.");
            return; // Exit the function if projectId is not valid
        }

        try {
            const response = await axios.get(`${url}/api/tasks/project/${projectId}`);
            const sortedData = response.data.data.sort((a, b) => b.id - a.id);
            console.log(sortedData, "sorted");

            setLocalData(sortedData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    const [project, setProject] = useState(null);

    useEffect(() => {
        const getProjectData = async () => {
            const data = await fetchProject(projectId);
            setProject(data);
        };

        getProjectData();
    }, [projectId]);

    // Rest of your component


    const fetchProject = async (projectId) => {
        try {
            const response = await axios.get(`${url}/api/projects/project/${projectId}`);
            console.log(response.data);

            return response.data;
        } catch (error) {
            console.error("Error fetching project data:", error);
            // Handle error as needed
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTaskData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const handleEdit = (item) => {
        setTaskData({
            id: item.id,
            taskName: item.taskName,
            description: item.description,
            lead: convertUserToOption(item.leadUser),
            owner: convertUserToOption(item.ownerUser),
            priority: item.priority,
            status: item.status,
            plannedStartDate: item.plannedStartDate ? item.plannedStartDate.slice(0, 10) : '',
            actualStartDate: item.actualStartDate ? item.actualStartDate.slice(0, 10) : '',
            plannedEndDate: item.plannedEndDate ? item.plannedEndDate.slice(0, 10) : '',
            actualEndDate: item.actualEndDate ? item.actualEndDate.slice(0, 10) : '',
            notes: item.notes
        });
        setEditingItem(item);
        setShowEditModal(true);
    };


    function getFirstFiveWords(str) {
        const words = str.split(' '); // Split the string into words
        return words.slice(0, 5).join(' '); // Join the first 5 words
    }

    const handleSelectChange = (name, selectedOption) => {
        setTaskData((prevData) => ({
            ...prevData,
            [name]: selectedOption,
        }));
    };



    const columns = React.useMemo(
        () => [
            {
                Header: "S.No",
                id: "serial-number", // This can be any unique string
                Cell: ({ row }) => {
                    return (
                        <div style={{ textAlign: "center" }}>
                            {row.index + 1}
                        </div>
                    );
                }
            },
            {
                Header: "Milestone Name",
                accessor: "taskName",
                Filter: DropdownFilter,
                filter: multiSelectFilterFn,
                Cell: ({ value }) => {
                    // Check if value is not null or undefined before accessing its length
                    if (value) {
                        const firstFiveWords = getFirstFiveWords(value);
                        const displayText = firstFiveWords.length < value.length ? firstFiveWords + "..." : firstFiveWords;

                        return (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        {value}
                                    </Tooltip>
                                }
                            >
                                <div style={{ textAlign: "left" }}>{displayText}</div>
                            </OverlayTrigger>
                        );
                    }
                },
            },
            {
                Header: "Description",
                accessor: "description",
                Filter: DropdownFilter,
                width: 200,
                filter: multiSelectFilterFn,
                Cell: ({ value }) => {
                    // Check if value is not null or undefined before accessing its length
                    if (value) {
                        const firstFiveWords = getFirstFiveWords(value);
                        const displayText = firstFiveWords.length < value.length ? firstFiveWords + "..." : firstFiveWords;

                        return (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        {value}
                                    </Tooltip>
                                }
                            >
                                <div style={{ textAlign: "left" }}>{displayText}</div>
                            </OverlayTrigger>
                        );
                    }
                    return <div style={{ textAlign: "center" }}>-</div>;
                },
            },
            {
                Header: "Lead",
                accessor: "lead",
                Filter: DropdownFilter,
                filter: multiSelectFilterFn,
                Cell: ({ row }) => {
                    const leadUser = row.original.leadUser;
                    if (leadUser) {
                        return (
                            <div className="d-flex align-items-center gap-2">
                                <Avatar
                                    img={leadUser.profileImage || dummyImage}
                                    alt="Lead Avatar"
                                />
                                <span>{leadUser.displayName}</span>
                            </div>
                        );
                    }
                    return <span>No Lead Assigned</span>;
                },
            },

            {
                Header: "Owner",
                accessor: "owner",
                Filter: DropdownFilter,
                filter: multiSelectFilterFn,
                Cell: ({ row }) => (
                    <div className="d-flex align-items-center gap-2">
                        <Avatar
                            img={row.original.ownerUser.profileImage || dummyImage}
                            alt="Owner Avatar"
                        />

                        <span>{row.original.ownerUser.displayName}</span>
                    </div>
                ),
            },
            {
                Header: "Priority",
                accessor: "priority",
                Cell: ({ value }) => {
                    let bgColor, textColor, center, bold;
                    switch (value) {
                        case "HIGH":
                            bgColor = "danger";
                            textColor = "white";
                            center = "center";
                            bold = "bold";
                            break;
                        case "MEDIUM":
                            bgColor = "warning";
                            textColor = "white";
                            center = "center";
                            bold = "bold";
                            break;
                        case "LOW":
                            bgColor = "info";
                            textColor = "white";
                            center = "center";
                            bold = "bold";
                            break;
                        case "NA":
                            bgColor = "success";
                            textColor = "white";
                            center = "center";
                            bold = "bold";
                            break;
                        default:
                            bgColor = "white"; // Default background color if none of the above
                    }
                    if (
                        value === "HIGH" ||
                        value === "MEDIUM" ||
                        value === "LOW" ||
                        value === "NA"
                    ) {
                        return (
                            <center>
                                <Badge bg={bgColor} size="lg" pill>
                                    {value}
                                </Badge>
                            </center>
                        );
                    }
                    return (
                        <div
                            style={{
                                backgroundColor: bgColor,
                                color: textColor,
                                fontWeight: bold,
                                textAlign: center,
                            }}
                        >
                            {value}
                        </div>
                    );
                },
                // eslint-disable-next-line
                Filter: DropdownFilter,
                // eslint-disable-next-line
                filter: multiSelectFilterFn,
            },
            {
                Header: "Status",
                accessor: "status",
                filter: multiSelectFilterFn,
                Cell: ({ value }) => {
                    let bgColor, textColor, center, bold;

                    switch (value) {
                        case "NOT STARTED":
                            bgColor = "danger"; // Change to "danger" for NOT STARTED
                            textColor = "white";
                            center = "center";
                            bold = "bold";
                            break;
                        case "IN PROGRESS":
                            bgColor = "primary"; // Change to "primary" for IN PROGRESS
                            textColor = "white";
                            center = "center";
                            bold = "bold";
                            break;
                        case "ON HOLD":
                            bgColor = "warning"; // Change to "warning" for ON HOLD
                            textColor = "white";
                            center = "center";
                            bold = "bold";
                            break;
                        case "COMPLETED":
                            bgColor = "success";
                            textColor = "white";
                            center = "center";
                            bold = "bold";
                            break;
                        case "OVERDUE":
                            bgColor = "danger";
                            textColor = "black";
                            center = "center";
                            bold = "bold";
                            break;
                        default:
                            bgColor = "white"; // Default background color if none of the above
                    }

                    if (
                        value === "NOT STARTED" ||
                        value === "IN PROGRESS" ||
                        value === "ON HOLD" ||
                        value === "OVERDUE" ||
                        value === "COMPLETED"
                    ) {
                        return (
                            <center>
                                <Badge bg={bgColor}>{value}</Badge>
                            </center>
                        );
                    }

                    return (
                        <div
                            style={{
                                backgroundColor: bgColor,
                                color: textColor,
                                fontWeight: bold,
                                textAlign: center,
                            }}
                        >
                            {value}
                        </div>
                    );
                },
                Filter: DropdownFilter,
            },
            {
                Header: 'P.S.D',
                accessor: 'plannedStartDate',
                Filter: DropdownFilter,
                filter: multiSelectFilterFn,
                dataType: "DATE",
                Cell: ({ value }) => (
                    <div style={{ textAlign: "center" }}>{formatDate(value)}</div>
                ),
            },
            {
                Header: 'A.S.D',
                accessor: 'actualStartDate',
                dataType: "DATE",
                Filter: DropdownFilter,
                filter: multiSelectFilterFn,
                Cell: ({ value }) => (
                    <div style={{ textAlign: "center" }}>{formatDate(value)}</div>
                ),
            },
            {
                Header: 'P.E.D',
                dataType: "DATE",
                Filter: DropdownFilter,
                filter: multiSelectFilterFn,
                accessor: 'plannedEndDate',
                Cell: ({ value }) => (
                    <div style={{ textAlign: "center" }}>{formatDate(value)}</div>
                ),
            },
            {
                Header: 'A.E.D',
                dataType: "DATE",
                accessor: 'actualEndDate',
                Filter: DropdownFilter,
                filter: multiSelectFilterFn,
                Cell: ({ value }) => (
                    <div style={{ textAlign: "center" }}>{formatDate(value)}</div>
                ),
            },
            {
                Header: 'Notes/Comments',
                accessor: 'notes',
                Filter: DropdownFilter,
                filter: multiSelectFilterFn,
                Cell: ({ value }) => {
                    // Check if value is not null or undefined before accessing its length
                    if (value) {
                        const firstFiveWords = getFirstFiveWords(value);
                        const displayText = firstFiveWords.length < value.length ? firstFiveWords + "..." : firstFiveWords;

                        return (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        {value}
                                    </Tooltip>
                                }
                            >
                                <div style={{ textAlign: "left" }}>{displayText}</div>
                            </OverlayTrigger>
                        );
                    } else {
                        // Handle the case where value is null or undefined
                        return <div style={{ textAlign: "center" }}>-</div>;

                    }
                },
                // Additional properties like Filter can be added if needed
            },
            {
                Header: "Actions",
                accessor: "editDelete",
                Cell: ({ row }) => (
                    <div style={{ textAlign: "center" }}>
                        <span
                            className="edit-icon"
                            onClick={() => handleEdit(row.original)}
                        >
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        <strong>Edit</strong>
                                    </Tooltip>
                                }
                            >
                                <Edit size={20} color="blue" />
                            </OverlayTrigger>
                        </span>

                        <span
                            className="delete-icon"
                            onClick={() => openDeleteModal(row.original)}
                        >
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        <strong>Delete</strong>
                                    </Tooltip>
                                }
                            >
                                <Trash2 size={20} color="red" />
                            </OverlayTrigger>
                        </span>
                    </div>
                ),
            },
        ],
        // eslint-disable-next-line
        []
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenFilter(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        // eslint-disable-next-line
        state: { pageIndex, pageSize },
        nextPage,
        previousPage,
        setGlobalFilter,
        gotoPage, // Add gotoPage as a prop
        canNextPage,
        pageCount,
        canPreviousPage,
    } = useTable(
        {
            columns,
            data: localData,
            initialState: {
                pageIndex: 0,
                pageSize: 10,
                globalFilter: "",
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useResizeColumns,
        useGlobalFilter,
        useSortBy,
        useResizeColumns,
        usePagination
    );

    React.useEffect(() => {
        setGlobalFilter(globalFilter); // This is how you set the global filter
    }, [globalFilter, setGlobalFilter]);

    const handleFilterChange = (e) => {
        const value = e.target.value || undefined;
        setGlobalFilter(value); // Set the filter globally
        setFilterInput(value); // Update the state with the new search input
    };
    const itemsPerPage = 4;
    const [currentPageGroup, setCurrentPageGroup] = useState(0);

    // Calculate the range of pages to display based on the current group
    const startIndex = currentPageGroup * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Create an array of page numbers for the current group
    const circleOptions = Array.from(
        { length: pageCount },
        (_, index) => index + 1
    ).slice(startIndex, endIndex);

    return (
        <>
            <AdminProjectHeader></AdminProjectHeader>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item">
                                <Link to="/admin/projects">Project Management</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Milestones
                            </li>
                            <li className="breadcrumb-item active" aria-current="page"></li>
                        </ol>
                    </div>

                    {/* <div style={{ textAlign: "center", padding: "10px" }}>
                        {alertMessage && <Alert variant={alertType}>{alertMessage}</Alert>}
                    </div> */}





                    {/* {alertMessage && <Alert variant={alertType}>{alertMessage}</Alert>} */}

                    <div className="d-flex justify-content-center align-items-center mt-3 mt-md-0">



                        {/* Add Task Modal */}
                        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>


                            <Modal.Header closeButton>
                                <Modal.Title>Add Milestone</Modal.Title>
                            </Modal.Header>


                            <Modal.Body>
                                <Container>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                Milestone Name{" "}
                                                <span style={{ color: "red", marginLeft: "5px" }}>
                                                    *
                                                </span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="taskName"
                                                value={taskData.taskName}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="description"
                                                value={taskData.description}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                Lead Name{" "}
                                                <span style={{ color: "red", marginLeft: "5px" }}>
                                                    *
                                                </span>
                                            </Form.Label>
                                            <Select
                                                options={[...leadSelectOptions].sort((a, b) =>
                                                    a.label.localeCompare(b.label)
                                                )}
                                                isSearchable={true}
                                                value={leadSelectOptions.find(
                                                    (option) => option.value === taskData.lead
                                                )}
                                                onChange={(selectedOption) =>
                                                    handleInputChange({
                                                        target: {
                                                            name: "lead",
                                                            value: selectedOption.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                Owner Name{" "}
                                                <span style={{ color: "red", marginLeft: "5px" }}>
                                                    *
                                                </span>
                                            </Form.Label>

                                            <Select
                                                options={[...ownerSelectOptions].sort((a, b) =>
                                                    a.label.localeCompare(b.label)
                                                )}
                                                isSearchable={true}
                                                value={ownerSelectOptions.find(
                                                    (option) => option.value === taskData.owner
                                                )}
                                                onChange={(selectedOption) =>
                                                    handleInputChange({
                                                        target: {
                                                            name: "owner",
                                                            value: selectedOption.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                Priority
                                                <span style={{ color: "red", marginLeft: "5px" }}>
                                                    *
                                                </span>
                                            </Form.Label>
                                            <Select
                                                options={[
                                                    { value: "HIGH", label: "HIGH" },
                                                    { value: "MEDIUM", label: "MEDIUM" },
                                                    { value: "LOW", label: "LOW" },
                                                    { value: "NA", label: "NA" },
                                                ]}
                                                isSearchable={true}
                                                value={[
                                                    { value: "HIGH", label: "HIGH" },
                                                    { value: "MEDIUM", label: "MEDIUM" },
                                                    { value: "LOW", label: "LOW" },
                                                    { value: "NA", label: "NA" },
                                                ].find((option) => option.value === taskData.priority)}
                                                onChange={(selectedOption) =>
                                                    handleInputChange({
                                                        target: {
                                                            name: "priority",
                                                            value: selectedOption.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                Status
                                                <span style={{ color: "red", marginLeft: "5px" }}>
                                                    *
                                                </span>
                                            </Form.Label>
                                            <Select
                                                options={[
                                                    { value: "COMPLETED", label: "COMPLETED" },
                                                    { value: "IN PROGRESS", label: "IN PROGRESS" },
                                                    { value: "NOT STARTED", label: "NOT STARTED" },
                                                    { value: "ON HOLD", label: "ON HOLD" },
                                                    { value: "OVERDUE", label: "OVERDUE" },
                                                ]}
                                                isSearchable={true}
                                                value={[
                                                    { value: "COMPLETED", label: "COMPLETED" },
                                                    { value: "IN PROGRESS", label: "IN PROGRESS" },
                                                    { value: "NOT STARTED", label: "NOT STARTED" },
                                                    { value: "ON HOLD", label: "ON HOLD" },
                                                    { value: "OVERDUE", label: "OVERDUE" },
                                                ].find((option) => option.value === taskData.status)}
                                                onChange={(selectedOption) =>
                                                    handleInputChange({
                                                        target: {
                                                            name: "status",
                                                            value: selectedOption.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Planned Start Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="plannedStartDate"
                                                value={taskData.plannedStartDate}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Actual Start Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="actualStartDate"
                                                value={taskData.actualStartDate}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Planned End Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="plannedEndDate"
                                                value={taskData.plannedEndDate}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Actual End Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="actualEndDate"
                                                value={taskData.actualEndDate}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Notes/Comments</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="notes"
                                                rows={3}
                                                value={taskData.notes}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>




                                    </Form>
                                </Container>
                                <div style={{ textAlign: "center", padding: "10px" }}>
                                    {alertMessage && <Alert variant={alertType}>{alertMessage}</Alert>}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => setShowAddModal(false)}
                                >
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleSubmitAdd}>
                                    Add
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Edit Modal */}

                        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Milestone</Modal.Title>
                            </Modal.Header>
                            <div style={{ textAlign: "center", padding: "10px" }}>
                                {alertMessage && (
                                    <Alert variant={alertType}>{alertMessage}</Alert>
                                )}
                            </div>

                            <Modal.Body>
                                <Container>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                Milestone Name
                                                <span style={{ color: "red", marginLeft: "5px" }}>
                                                    *
                                                </span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="taskName"
                                                value={taskData.taskName}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="description"
                                                value={taskData.description}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                Lead Name{" "}
                                                <span style={{ color: "red", marginLeft: "5px" }}>
                                                    *
                                                </span>
                                            </Form.Label>
                                            <Select
                                                options={[...leadSelectOptions].sort((a, b) =>
                                                    a.label.localeCompare(b.label)
                                                )}
                                                isSearchable={true}
                                                value={taskData.lead}
                                                onChange={(selectedOption) => handleSelectChange("lead", selectedOption)}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                Owner Name{" "}
                                                <span style={{ color: "red", marginLeft: "5px" }}>
                                                    *
                                                </span>
                                            </Form.Label>
                                            <Select
                                                options={[...ownerSelectOptions].sort((a, b) =>
                                                    a.label.localeCompare(b.label)
                                                )}
                                                isSearchable={true}
                                                value={taskData.owner}
                                                onChange={(selectedOption) => handleSelectChange("owner", selectedOption)}
                                            />
                                        </Form.Group>


                                        <Form.Group>
                                            <Form.Label
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                Priority
                                                <span style={{ color: "red", marginLeft: "5px" }}>
                                                    *
                                                </span>
                                            </Form.Label>
                                            <Select
                                                options={[
                                                    { value: "HIGH", label: "HIGH" },
                                                    { value: "MEDIUM", label: "MEDIUM" },
                                                    { value: "LOW", label: "LOW" },
                                                    { value: "NA", label: "NA" },
                                                ]}
                                                isSearchable={true}
                                                value={[
                                                    { value: "HIGH", label: "HIGH" },
                                                    { value: "MEDIUM", label: "MEDIUM" },
                                                    { value: "LOW", label: "LOW" },
                                                    { value: "NA", label: "NA" },
                                                ].find((option) => option.value === taskData.priority)}
                                                onChange={(selectedOption) =>
                                                    handleInputChange({
                                                        target: {
                                                            name: "priority",
                                                            value: selectedOption.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                Status
                                                <span style={{ color: "red", marginLeft: "5px" }}>
                                                    *
                                                </span>
                                            </Form.Label>
                                            <Select
                                                options={[
                                                    { value: "COMPLETED", label: "COMPLETED" },
                                                    { value: "IN PROGRESS", label: "IN PROGRESS" },
                                                    { value: "NOT STARTED", label: "NOT STARTED" },
                                                    { value: "ON HOLD", label: "ON HOLD" },
                                                    { value: "OVERDUE", label: "OVERDUE" },
                                                ]}
                                                isSearchable={true}
                                                value={[
                                                    { value: "COMPLETED", label: "COMPLETED" },
                                                    { value: "IN PROGRESS", label: "IN PROGRESS" },
                                                    { value: "NOT STARTED", label: "NOT STARTED" },
                                                    { value: "ON HOLD", label: "ON HOLD" },
                                                    { value: "OVERDUE", label: "OVERDUE" },
                                                ].find((option) => option.value === taskData.status)}
                                                onChange={(selectedOption) =>
                                                    handleInputChange({
                                                        target: {
                                                            name: "status",
                                                            value: selectedOption.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Planned Start Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="plannedStartDate"
                                                value={taskData.plannedStartDate}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Actual Start Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="actualStartDate"
                                                value={taskData.actualStartDate}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Planned End Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="plannedEndDate"
                                                value={taskData.plannedEndDate}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Actual End Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="actualEndDate"
                                                value={taskData.actualEndDate}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Notes/Comments</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="notes"
                                                rows={3}
                                                value={taskData.notes}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>



                                    </Form>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => setShowEditModal(false)}
                                >
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleSubmitEdit}>
                                    Update
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Delete Modal */}



                        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirm Deletion</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to delete
                                <span style={{ color: 'red' }}> {itemToDelete?.taskName} </span>?
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={closeDeleteModal}>
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>

                <Row className="g-12">
                    <Col lg="12" md="12" sm="12">



                        {/* 
                        <div className="cards-container">
                            <Card className="custom-project-card">
                                <Card.Body className="project-card-body">
                                    <h5>Project Name</h5>
                                    <Card.Text>
                                        {project ? project.data.projectName : 'Loading...'}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className="custom-project-card">
                                <Card.Body className="project-card-body">
                                    <h5>Project Description</h5>
                                    <Card.Text>
                                        {project ? project.data.description : 'Loading...'}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div> */}

                        <div className="cards-container">
                            {project ? (
                                <Card className="custom-project-card">
                                    <Card.Body className="project-card-body">
                                        <Card.Title>Project Details</Card.Title>
                                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                                            <li><strong>Project Name:</strong> {project.data.projectName}</li>
                                            <li><strong>Description:</strong> {project.data.description}</li>
                                            <li><strong>Planned Start Date:</strong> {formatDate(project.data.plannedStartDate || 'N/A')}</li>
                                            <li><strong>Actual Start Date:</strong> {formatDate(project.data.actualStartDate || 'N/A')}</li>
                                            <li><strong>Planned End Date:</strong> {formatDate(project.data.plannedEndDate || 'N/A')}</li>
                                            <li><strong>Actual End Date:</strong> {formatDate(project.data.actualEndDate || 'N/A')}</li>
                                            <li><strong>Type:</strong> {project.data.type}</li>
                                            <li><strong>Client:</strong> {project.data.client}</li>
                                            <li><strong>Statement of Work (SoW):</strong> {formatDate(project.data.SoW || 'N/A')}</li>
                                            <li><strong>Signed Date:</strong> {formatDate(project.data.signedDate || 'N/A')}</li>
                                            <li><strong>Resources:</strong> {project.data.resources}</li>
                                            <li><strong>Budget (INR):</strong>  ₹ {convertValueToWords(project.data.budgetINR)}</li>
                                            <li><strong>Major Risks:</strong> {project.data.majorRisks}</li>
                                            <li><strong>Notes/Comments:</strong> {project.data.notesComments}</li>
                                            <li><strong>Created At:</strong> {formatDate(project.data.createdAt)}</li>
                                            <li><strong>Updated At:</strong> {formatDate(project.data.updatedAt)}</li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            ) : (
                                <div>Loading...</div>
                            )}
                        </div>


                        <Card className="card-one card2">

                            <Card.Body className="px-2 pb-3">
                                <div className="grid-container">
                                    <Button
                                        variant="primary"
                                        className="d-flex align-items-center gap-2"
                                        onClick={openAddModal}
                                    >
                                        <i className="ri-add-line fs-18 lh-1"></i>Add Milestone
                                    </Button>
                                    <br></br>
                                    {/* Table */}
                                    <div className="table-responsive">
                                        <div style={{ textAlign: "center", padding: "10px" }}>
                                            {alertMessage && (
                                                <Alert variant={alertType}>{alertMessage}</Alert>
                                            )}
                                        </div>

                                        <table {...getTableProps()} className="table-style">
                                            {/* Thead */}
                                            <thead className="custom-thead">
                                                {headerGroups.map((headerGroup) => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map((column, columnIndex) => (
                                                            <th
                                                                {...column.getHeaderProps()}
                                                                className="header-style text-center"
                                                                key={column.id}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <div {...column.getSortByToggleProps()}>
                                                                        {column.render("Header")}
                                                                        <span>
                                                                            {column.isSorted ? (
                                                                                column.isSortedDesc ? (
                                                                                    <ChevronDown size={16} />
                                                                                ) : (
                                                                                    <ChevronUp size={16} />
                                                                                )
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    {[3, 4, 5, 6, 7, 8, 9, 10].includes(columnIndex) &&
                                                                        column.canFilter && (
                                                                            <div
                                                                                className="filter-align"
                                                                                onClick={() =>
                                                                                    openFilter === column.id
                                                                                        ? setOpenFilter(null)
                                                                                        : setOpenFilter(column.id)
                                                                                }
                                                                            >
                                                                                <Filter size={16} />
                                                                            </div>
                                                                        )}
                                                                </div>

                                                                {openFilter === column.id && (
                                                                    <div
                                                                        className="dropdown-filter"
                                                                        ref={dropdownRef}
                                                                    >
                                                                        {column.render("Filter")}
                                                                    </div>
                                                                )}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            {/* Tbody */}
                                            <tbody {...getTableBodyProps()}>
                                                {page.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()}>
                                                            {row.cells.map((cell) => {
                                                                if (cell.column.id === "lead") {
                                                                    const leadUser = row.original.leadUser;
                                                                    if (leadUser) {
                                                                        return (
                                                                            <td {...cell.getCellProps()}>
                                                                                <div className="d-flex align-items-center gap-2">
                                                                                    <Avatar
                                                                                        img={leadUser.profileImage || dummyImage}
                                                                                        alt="Lead Avatar"
                                                                                    />
                                                                                    <span>{leadUser.displayName}</span>
                                                                                </div>
                                                                            </td>
                                                                        );
                                                                    }
                                                                    return <td {...cell.getCellProps()}>No Lead Assigned</td>;
                                                                } else if (cell.column.id === "owner") {
                                                                    const ownerUser = row.original.ownerUser;
                                                                    if (ownerUser) {
                                                                        return (
                                                                            <td {...cell.getCellProps()}>
                                                                                <div className="d-flex align-items-center gap-2">
                                                                                    <Avatar
                                                                                        img={ownerUser.profileImage || dummyImage}
                                                                                        alt="Owner Avatar"
                                                                                    />
                                                                                    <span>{ownerUser.displayName}</span>
                                                                                </div>
                                                                            </td>
                                                                        );
                                                                    }
                                                                    return <td {...cell.getCellProps()}>No Owner Assigned</td>;
                                                                } else {
                                                                    return (
                                                                        <td {...cell.getCellProps()} className="cell-style">
                                                                            {cell.render("Cell")}
                                                                        </td>
                                                                    );
                                                                }
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>

                                        </table>
                                    </div>

                                    <Pagination className="pagination-space pagination-circled mb-0">
                                        <Pagination.Item
                                            className="pagination-button"
                                            disabled={!canPreviousPage}
                                            onClick={() => {
                                                if (currentPageGroup > 0) {
                                                    previousPage();
                                                    setCurrentPageGroup(currentPageGroup - 1); // Decrement the currentPageGroup
                                                }
                                            }}
                                        >
                                            <i className="ri-arrow-left-s-line"></i>
                                        </Pagination.Item>
                                        <div className="pagination-circle-container">
                                            {circleOptions.map((page) => (
                                                <Pagination.Item
                                                    key={page} // Add the key prop here
                                                    active={pageIndex + 1 === page}
                                                    onClick={() => gotoPage(page - 1)}
                                                    className={`pagination-circle ${pageIndex + 1 === page ? "active" : ""
                                                        }`}
                                                >
                                                    {page}
                                                </Pagination.Item>
                                            ))}
                                        </div>
                                        <Pagination.Item
                                            className="pagination-button"
                                            disabled={!canNextPage}
                                            onClick={() => {
                                                nextPage();
                                                setCurrentPageGroup(currentPageGroup + 1); // Increment the currentPageGroup
                                            }}
                                        >
                                            <i className="ri-arrow-right-s-line"></i>
                                        </Pagination.Item>
                                    </Pagination>
                                </div>
                                <Footer />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default AdminTaskManagement;




function toTitleCase(str) {
    if (!str) {
        // Return an empty string or any other default value if str is null or undefined
        return '';
    }

    return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}



const DropdownFilter = ({ column }) => {
    const { filterValue = [], setFilter, preFilteredRows, id } = column;



    // Function to format the value based on its data type
    const formatValue = (value, dataType) => {
        switch (dataType) {
            case 'DATE':
                return formatDate(value);
            case 'ENUM':
            case 'STRING':
                return value ? value.toString() : 'N/A';
            default:
                return value ? value.toString() : 'N/A';
        }
    };

    // State to manage "Select All" functionality
    const [selectAll, setSelectAll] = useState(false);

    // Get unique values from preFilteredRows
    const options = useMemo(() => {
        const optionsSet = new Set();
        preFilteredRows.forEach((row) => {
            const formattedValue = formatValue(row.values[id], column.dataType);
            optionsSet.add(formattedValue);
        });
        return Array.from(optionsSet);
    }, [id, preFilteredRows, column.dataType]);

    const toggleOption = (option) => {
        const index = filterValue.indexOf(option);
        const newFilterValue = [...filterValue];
        if (index === -1) {
            newFilterValue.push(option);
        } else {
            newFilterValue.splice(index, 1);
        }
        setFilter(newFilterValue.length ? newFilterValue : undefined);
    };

    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
        setFilter(selectAll ? [] : options);
    };

    useEffect(() => {
        // Automatically update selectAll based on filterValue
        setSelectAll(filterValue.length === options.length);
    }, [filterValue, options]);

    return (
        <div className="filter-dropdown">
            <div className="filter-header">
                <button className="check-all-button" onClick={toggleSelectAll}>
                    {selectAll ? "Uncheck All" : "Check All"}
                </button>
            </div>
            <div className="filter-body">
                {options.map((option, index) => (
                    <label key={index} className="checkbox-label">
                        <input
                            type="checkbox"
                            value={option}
                            checked={filterValue.includes(option)}
                            onChange={() => toggleOption(option)}
                        />
                        {toTitleCase(option)}
                    </label>
                ))}
            </div>
            <div className="filter-footer">
                <button className="clear-filter" onClick={() => setFilter(undefined)}>
                    Clear
                </button>
            </div>
        </div>
    );
};

function formatDate(dateString) {
    // Check for null, undefined, or empty string
    if (!dateString) {
        return '-';
    }

    const date = new Date(dateString);

    // Check for invalid date
    if (isNaN(date.getTime())) {
        return '-';
    }

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear().toString().substr(-2);

    const ordinalSuffix = (n) => {
        if (n > 3 && n < 21) return 'th'; // for numbers like 11th, 12th, 13th, etc.
        switch (n % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    };

    return `${day}${ordinalSuffix(day)} ${month} ${year}`;
}


function convertValueToWords(value) {
    // Parsing the numeric value
    const numericValue = typeof value === 'string' ? parseInt(value, 10) : value;

    // Check if value is null, undefined, or not a number
    if (numericValue === null || numericValue === undefined || isNaN(numericValue)) {
        return null;
    }

    // Function to convert number to words in Indian format
    function numberToWords(number) {
        if (number === 0) return 'Zero';

        const arr = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        function convert(n) {
            if (n < 20) return arr[n];
            if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 > 0 ? ' ' + arr[n % 10] : '');
            if (n < 1000) return arr[Math.floor(n / 100)] + ' Hundred' + (n % 100 > 0 ? ' ' + convert(n % 100) : '');
            if (n < 100000) return convert(Math.floor(n / 1000)) + ' Thousand' + (n % 1000 > 0 ? ' ' + convert(n % 1000) : '');
            if (n < 10000000) return convert(Math.floor(n / 100000)) + ' Lakh' + (n % 100000 > 0 ? ' ' + convert(n % 100000) : '');
            if (n < 1000000000) return convert(Math.floor(n / 10000000)) + ' Crore' + (n % 10000000 > 0 ? ' ' + convert(n % 10000000) : '');
            return 'Number too large';
        }

        return convert(number);
    }

    // Function to convert to Title Case
    function toTitleCase(str) {
        return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    // Function to format number with Indian style commas
    function formatNumberIndianStyle(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    // Converting number to words and then to title case
    const words = numberToWords(numericValue);
    const titleCaseWords = toTitleCase(words);

    // Formatting number with Indian style commas
    const formattedValue = formatNumberIndianStyle(numericValue);

    return `${formattedValue} (${titleCaseWords} Rupees)`;
}